<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        v-model="activeKey"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <!-- 从文件列表打开，只能展示当前表单 -->
        <a-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :tab="item.name"
          :disabled="item.disabled"
        ></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload
          :isView="isView"
          :url="imgUrl"
          :width="'60%'"
          @exportForm="exportForm"
          @download="download"
        />
      </template>
    </Pane>

    <div class="container">
      <Comment
        v-if="activeKey === '0'"
        ref="commentRef"
        :value="pData"
      ></Comment>
      <Approval v-if="activeKey === '1'" ref="appRef" :value="pData"></Approval>
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import Comment from "./components/comment.vue"; //成本执行报告审核
import Approval from "./components/approval.vue"; //成本执行报告审批

export default {
  name: "incExpCost",
  components: {
    ExportDownload,
    Comment,
    Approval,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/chengben.png"),

      activeKey: "0",
      tabList: [
        { key: "0", name: "成本执行报告审核", disabled: false },
        { key: "1", name: "成本执行报告审批", disabled: false },
      ],

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},
      // status: false,

      isView: false,
    };
  },
  watch: {
    activeKey(newV) {
      if (newV === "0") {
        this.imgUrl = require("@/assets/epc/approvalFlow/chengben.png");
      } else {
        this.imgUrl = require("@/assets/epc/approvalFlow/chengben2.jpg");
      }
    },
  },

  mounted() {
    const { query } = this.$route;
    const { activeKey, id, pid, isView, able } = query || {}; // 通过activeKey判断当前是哪个tab，id判断是否是编辑页面，isView判断是否是查看页面，

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid || "";
    this.id = id || "";
    this.isView = Boolean(isView) || false;

    // 前一个环节没有通过审批，后面tab不可点击；
    //或从编辑进入页面，设置非活跃状态的tab不可用
    if (id || able === "false") {
      this.setTab();
    }
  },
  methods: {
    setTab() {
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },

    download() {
      switch (this.activeKey) {
        case "0":
          this.$refs.commentRef.download();
          break;
        case "1":
          this.$refs.appRef.download();
          break;
      }
    },
    exportForm() {
      switch (this.activeKey) {
        case "0":
          this.$refs.commentRef.exportForm();
          break;
        case "1":
          this.$refs.appRef.exportForm();
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}
</style>
